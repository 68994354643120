exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-apartments-page-index-tsx": () => import("./../../../src/templates/ApartmentsPage/index.tsx" /* webpackChunkName: "component---src-templates-apartments-page-index-tsx" */),
  "component---src-templates-breakfast-page-index-tsx": () => import("./../../../src/templates/BreakfastPage/index.tsx" /* webpackChunkName: "component---src-templates-breakfast-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-hotel-page-index-tsx": () => import("./../../../src/templates/HotelPage/index.tsx" /* webpackChunkName: "component---src-templates-hotel-page-index-tsx" */),
  "component---src-templates-info-page-index-tsx": () => import("./../../../src/templates/InfoPage/index.tsx" /* webpackChunkName: "component---src-templates-info-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-meeting-page-index-tsx": () => import("./../../../src/templates/MeetingPage/index.tsx" /* webpackChunkName: "component---src-templates-meeting-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-quotation-page-index-tsx": () => import("./../../../src/templates/QuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-quotation-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-surroundings-page-index-tsx": () => import("./../../../src/templates/SurroundingsPage/index.tsx" /* webpackChunkName: "component---src-templates-surroundings-page-index-tsx" */)
}

